import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

const Profile = () => {

    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const climbingSets = useSelector(state => state.climbingSets)

    return (
        <div className='profile'>
            <div className='profile-header'>
                <img src={user.image_url} alt='profile-pic' />
                <h1>{user.username}</h1>
                <button onClick={() => navigate(`/${user.username}/edit`)}>Edit Profile</button>
            </div>
            <div className='profile-subheader'>
                <h1>{user.full_name}</h1>
                <h1>{user.points} points</h1>
                <h2>Level: {user.handicap}</h2>
                { user.team 
                ? <h2><Link to={`/teams/${user.team.slug}`}>{user.team.team_name} - {user.team.team_points} points</Link></h2> 
                : <h2>No Team</h2> }
            </div>
            <h2>Completed Climbs:</h2>
            <div className='week-set-container'>
                {climbingSets.map((set) => {
                    const completedClimbs = user.climbs_by_grade.filter((climb) => climb.climbing_set_id === set.id);

                    return (
                        <div key={set.id} className="week-set">
                            { set.set_name === 'Grasshopper Board' ? 
                            <h2>{set.set_name}</h2>:
                            <h2>Week {set.week}: {set.set_name}</h2>}
                            {completedClimbs.map((climb) => (
                                <div>
                                    {climb.color === 'Grasshopper' ? 
                                        <p key={climb.id}>{climb.name} @ {climb.wall_angle}° - V{climb.grade} - {climb.points} points</p> :
                                        <p key={climb.id}>{climb.color} {climb.grade} - {climb.points} points</p>}
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>    
        </div>
    );
};

export default Profile;