import React from 'react';

const Home = () => {
  
  return (
    <div className='home'>
      <h1>Welcome to Long Beach Rising Bouldering League!</h1>

        <p>Remember, the main goals of bouldering league are to:</p>  
          <li>Try your hardest and become a better and strong climber.</li>
          <li>Get to know other members in our community and climb with and create new friends.</li>
          <li>NOT GET INJURED... please warm up accordingly and remember no climb is worth an injury. Listen to your body and rest when needed!</li>

      <h2>Points System</h2>
        <p>Points are calculated based on an individuals pre-set level. Your climbing level should be something you could send within 5 tries. The scoring systems works like so:</p>
          <li>You are awarded 100 points for each climb completed at your pre-set level as well as 1 grade below your level.</li>
          <li>You are awarded extra points for each climb completed above your level: 150 points for 1 grade above and 200 points for 2 grades above.</li>
          <li>You are awarded 0 points for each climb completed 2 grades below your level.</li>
        <p>Disclaimers</p>
          <li>Points are only awarded for 2 grades above your level because if you are sending 3 grades above your level, you probably need to bump up your climbing level.</li>
          <li>Points are only awarded for 1 grade below you hanidcap because it is assumed that you should be able to complete a problem 2 grades below your level fairly easily.</li>
          <li>This scoring system allows the available points to be earned to be fair at every climbing level.</li>
        <p>Example</p>
          <p>Chris Shawarma - Level: 5</p>
          <p>If Chris sends every climb level 1 - 9 on a climbing set, points will be awarded as follows:</p>
          <li>Level 1-3: 0 points</li>
          <li>Level 4-5: 100 points</li>
          <li>Level 6: 150 points</li>
          <li>Level 7: 200 points</li>
          <li>Level 8-9: 0 points... Chris needs to move up his climbing level.</li>

        <h2>Rules</h2>
          <li>Each team will consist of 4 climbers.</li>
          <li>Every climber has one week to complete and submit their climbs to recieve full points.</li>
          <li>Points will be awarded only if the climbs are completed within the time frame of the current bouldering league week.</li>
          <li>Each week of boudlering league will begin on the Tuesday and end the following Tuesday. This gives every climber 8 days to complete and submit their climbs.</li>
          <li>If you are sick, or traveling for an extended period of time, just let us know and we can figure something out for you.</li>
        
        <h2>Prizes</h2>
          <li>1st Place: Mad Rock Climbing Shoes for each member of the team</li>
          <li>2nd Place: Probably something?</li>
          <li>3rd Place: Probably something... maybe?</li>


        <h2>Problems/Suggestions</h2>
        <p>If you have any issues or suggestions to make the web app better, please email dev.connorvosberg@gmail.com</p>
    </div>
  )
}

export default Home;