import React, { useState } from 'react';
import SignUpForm from '../components/SignUpForm';
import LoginForm from '../components/LoginForm';

import USA_LBR from '../media/USA_LBR_with_banner.png';

const Login = () => {

    const [showSignUp, setShowSignUp] = useState(false);
    const [showLogin, setShowLogin] = useState(true);

    function signUpDisplay() {
        setShowSignUp(true);
        setShowLogin(false);
    }

    function loginDisplay() {
        setShowLogin(true);
        setShowSignUp(false);
    }

    return (
        <div  className = 'login-container'>
            <div className = 'login-header'>
                    <img src={USA_LBR} alt="LBR Logo" />
            </div>
            <div>
                { showLogin ? <LoginForm /> : <SignUpForm />}
                { showLogin ? <p>If you don't already have an account, click <button onClick = {signUpDisplay}>here</button> to sign up.</p> : <p>If you already have an account, click <button onClick = {loginDisplay}>here</button> to log in.</p>}
            </div>
            <p>Having problems? Please email: dev.connorvosberg@gmail.com</p>
        </div>
    )
}

export default Login;