import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/user';
import { setLoginError } from '../redux/error';
import { setSignupError } from '../redux/error';

import ForgotPassword from './ForgotPassword';
import Loading from './Loading';


const LoginForm = () => {


    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const error = useSelector((state) => state.error.loginError);



    function handleLogin(e) {
        e.preventDefault()
        setLoading(true);
        fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username, password
            })
        })
        .then((r) => {
            if (r.ok) {
                r.json().then((user) => {
                    dispatch(setUser(user))
                    dispatch(setLoginError(null));
                    dispatch(setSignupError(null));
                    navigate('/')
                });
            } else {
                r.json().then((errors) => {
                    dispatch(setLoginError(errors.errors))
                    setUsername('');
                    setPassword('');
                })
            }
        })
        .finally(() => {
            setLoading(false);
        });  
    }

  return (

    <div className = 'login-signup-form' >
        <form onSubmit = {handleLogin}>
        <input 
            type="text" 
            name="username" 
            placeholder='Username or Email'
            value={username} 
            onChange = {(e) => setUsername(e.target.value)} 
        /> <br></br>
        <input 
            type="password" 
            name="password" 
            placeholder='Password'
            value={password} 
            onChange = {(e) => setPassword(e.target.value)}
        /> <br></br>
        <button type="submit" disabled={loading}>
            {loading ? <Loading /> : 'Log In'}
        </button>
        </form>
        <ForgotPassword />
        {error?.map((err) => (
                <li key={err}>
                    {err}
                </li>
        ))}        
    </div>
  )
}

export default LoginForm;
