import React, {useState} from 'react';
import Loading from './Loading';

import { useDispatch } from 'react-redux';
import { addClimbingSetClimb } from '../redux/climbingSets';


const NewClimbForm = ({ set }) => {

    const [grade, setGrade] = useState('');
    const [color, setColor] = useState('');
    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [wallAngle, setWallAngle] = useState('');
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        const climbInfo = {
            grade: grade,
            color: color,
            name: name,
            link: link,
            wall_angle: wallAngle,
        }
        fetch(`/api/climbing_sets/${set.id}/climbs`, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(climbInfo)
        })
        .then((r) => {
            if (r.ok) {
                r.json().then((newClimb) => {
                    dispatch(addClimbingSetClimb(newClimb))
                })
                setGrade('')
                setColor('')
                setName('')
                setLink('')
                setWallAngle('')
            }
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='admin-new-climb-form'>
            <form onSubmit={handleSubmit}>
                <select name="color" value={color} onChange={(e) => setColor(e.target.value)}>
                    <option value="" disabled>Color</option>
                    <option value="Red">Red</option>
                    <option value="Orange">Orange</option>
                    <option value="Yellow">Yellow</option>
                    <option value="Green">Green</option>
                    <option value="Blue">Blue</option>
                    <option value="Purple">Purple</option>
                    <option value="Pink">Pink</option>
                    <option value="White">White</option>
                    <option value="Black">Black</option>
                    <option value="Wood">Wood</option>
                    <option value="Grasshopper">Grasshopper</option>
                </select>
                { color === 'Grasshopper' 
                    ?
                <div>
                    <input placeholder='Name' name='name' value={name} onChange={(e) => setName(e.target.value)} />
                    <input placeholder='Link' name='link' value={link} onChange={(e) => setLink(e.target.value)} />
                    <input placeholder='Wall Angle' name='wall-angle' value={wallAngle} onChange={(e) => setWallAngle(e.target.value)} />
                </div>
                    :
                null }
                
                { color === 'Grasshopper' 
                    ?
                <div>
                    <select name="grade" value={grade} onChange={(e) => setGrade(e.target.value)}>
                        <option value="" disabled>Grade</option>
                        <option value="1">V1</option>
                        <option value="2">V2</option>
                        <option value="3">V3</option>
                        <option value="4">V4</option>
                        <option value="5">V5</option>
                        <option value="6">V6</option>
                        <option value="7">V7</option>
                        <option value="8">V8</option>
                        <option value="9">V9</option>
                        <option value="10">V10</option>
                    </select>
                </div>
                    :
                <div>
                    <select name="grade" value={grade} onChange={(e) => setGrade(e.target.value)}>
                        <option value="" disabled>Grade</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
                }
                <button type="submit" disabled={loading}>
                    {loading ? <Loading /> : 'Submit'}
                </button>
            </form>
        </div>
    )
}

export default NewClimbForm;
