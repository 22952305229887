import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import VerifyCode from './VerifyCode';

const ForgotPassword = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [showVerificationCode, setShowVerificationCode] = useState(false)

  const navigate = useNavigate();

    function handleForgotPasswordClick(e) {
      e.preventDefault()
      setFormVisible(true);
      
    };

    function sendResetPasswordEmail(e) {
        e.preventDefault()
        fetch('/api/password_resets', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email})
        })
        .then((r) => {
            if(r.ok) {
                setShowEmailMessage(true);
                setFormVisible(false);
                setShowVerificationCode(true)
                navigate('/password/verify');
            }
        })
    }

  return (
    <div>
      {showVerificationCode ? (
        <VerifyCode email = {email}/>
      ) : (
        <div>
          <p>
            Forgot your password? <button onClick={handleForgotPasswordClick}>Reset Password</button>
          </p>
          {formVisible && (
            <form onSubmit={sendResetPasswordEmail}>
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit">Reset Password</button>
            </form>
          )}
          {showEmailMessage && (
            <p>If an account with that email was found, we have sent a link to reset the password.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;