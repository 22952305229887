import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const VerifyCode = ({ email }) => {

  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/verify_code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, code }),
    });
    const data = await response.json();
    if (data.success) {
      navigate(`/password/reset?token=${data.token}`);
    } else {
      setMessage(data.error);
    }
  };

  return (
    <div>
      <h2>Enter Verification Code</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter the code"
          required
        />
        <button type="submit">Verify Code</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default VerifyCode;