import React from 'react';

const Loading = () => {
    return (
        <div className="loading">
          <div className="spinner"></div>
        </div>
    );
}

export default Loading;