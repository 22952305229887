import React, { useEffect, useState } from 'react';

const Users = () => {

  const [allUsers, setAllUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [sortByPoints, setSortByPoints] = useState(false);
  const [sortByHandicap, setSortByHandicap] = useState(false);
  

  useEffect(() => {
      fetch("/api/users")
      .then((r) => {
        if (r.ok) {
          r.json().then((users) => {
            setAllUsers(users)
          })
        }
      });
    }, []);

    const handleSortChange = (type) => {
      if (type === 'points') {
        setSortByPoints(!sortByPoints);
        setSortByHandicap(false);
      } else if (type === 'handicap') {
        setSortByHandicap(!sortByHandicap);
        setSortByPoints(false);
      }
    };

    const filteredUsers = allUsers
    .filter((user) => user.full_name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortByPoints) {
        return b.points - a.points;
      }
      if (sortByHandicap) {
        return b.handicap - a.handicap;
      }
      return 0;
    });

    return (
      <div className='admin-user'>
        <div className='admin-user-filters'>
          <h2>Search for user:</h2>
          <input 
            type="text" 
            name="search" 
            placeholder="Name"
            value={search} 
            onChange={(e) => setSearch(e.target.value)} 
          />
          <h2>Sort by points:</h2>
          <input 
            type="checkbox" 
            name="sortbypoints" 
            checked={sortByPoints}
            onChange={() => handleSortChange('points')}
          />
          <h2>Sort by handicap:</h2>
          <input 
            type="checkbox" 
            name="sortbyhandicap" 
            checked={sortByHandicap}
            onChange={() => handleSortChange('handicap')}
          />
          <h2>Total Users: {allUsers?.length}</h2>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Points</th>
              <th>Level</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.full_name}</td>
                <td>{user.email}</td>
                <td>{user.points}</td>
                <td>{user.handicap}</td>
                <td>{user.team ? user.team.team_name : 'No Team'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default Users;
