import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LBR_Logo from '../media/LBR_logo.png';


const PasswordResetForm = ({ token }) => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        fetch (`/api/password/reset/edit/${token}`, {
            method: 'PATCH', 
            headers: { 
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify({
                user: {
                    password: password,
                    password_confirmation: passwordConfirmation,
                },
            })
        })
        .then((r) => {
            if(r.ok) {
                r.json().then((data) => {
                    alert('Password successfully updated. Please sign in.');
                    navigate('/');
                })
            } else {
                r.json().then((error) => {
                    alert(error.error)
                    setPassword('')
                    setPasswordConfirmation('')
                })
            }
        })
    };


    return (
        <div className='password-reset-form'>
            <img src={LBR_Logo} alt="LBR Logo" />
            <h2>Reset your password</h2>
            <form onSubmit={handleSubmit}>
                <div>
                <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                </div>
                <div>
                <input
                    type="password"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    placeholder='Password Confirmation'
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                </div>
                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
};

export default PasswordResetForm;